import {
  useMantineTheme,
  Box,
  rem,
  Text,
  Space,
  Tabs,
  Stack,
  Group,
  Paper,
  Progress,
  Button,
  Modal,
  Divider,
  TextInput,
  SimpleGrid,
  List,
  Image,
  Table,
  ScrollAreaAutosize,
  ScrollArea,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../lib/colors";
import * as Yup from "yup";
import { addProspectiveSponsor } from "../../actions/prospective-sponsor";
import { IconCircleCheck } from "@tabler/icons-react";
import { Form, Formik } from "formik";
import { formatToKECurrency, formatToUSDCurrency } from "../../lib/currency";
import { fetchSites } from "../../actions/sites";

function SponsorUsDetails() {
  const theme = useMantineTheme();

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = ({ data, resetForm }) => {
    addProspectiveSponsor({
      ...data,
      startLoader: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);

        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
          resetForm();
        }, 1000);
      },
      stopLoader: () => {
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Text c={Colors.brandTwo} fw={600} size="xl">
        How to sponsor us
      </Text>
      <Space h={"md"} />
      <Box>
        <Text fw={600} size="md">
          Paybill details (KES)
        </Text>
        <Space h="xs" />
        <Stack gap={0}>
          <Text size="sm" c={theme.colors.gray[7]}>
            Business no:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              7234187
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            Acc Name:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              Untapped Projects
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            NB: Paybill will read{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UNTAPPED WATER
            </Text>
            .
          </Text>
        </Stack>
      </Box>
      <Space h={"xl"} />
      <Box>
        <Text fw={600} size="md">
          Bank Details (USD)
        </Text>
        <Space h="xs" />
        <Stack gap={0}>
          <Text size="sm" c={theme.colors.gray[7]}>
            ACCOUNT NAME:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UNTAPPED WATER LIMITED
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            ACCOUNT NUMBER:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              4616780023 (USD)
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BANK NAME:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              NCBA BANK
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BANK CODE:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              07
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            SWIFT CODE:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              CBAFKENX
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BRANCH:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UPPER HILL
            </Text>
          </Text>
        </Stack>
      </Box>
      <Divider my={"lg"} color="black" />
      <Box>
        {/* <Text fw={600} c={Colors.brandTwo} size="xl">
          Let’s stay in touch
        </Text>
        <Text c={theme.colors.gray[7]} size="sm">
          Transparency is at the core of Untapped Water, as it fosters trust and
          accountability among our stakeholders. We believe that by openly
          sharing our processes, decisions, and data, we can create a more
          informed community that is engaged in the vital mission of sustainable
          water management.
        </Text>
        <Space h="sm" /> */}
        <Formik
          enableReinitialize
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit({ data: values, resetForm });
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Required"),
          })}
        >
          {({ handleChange, values, errors }) => (
            <Form>
              <SimpleGrid cols={2}>
                <TextInput
                  label="First name"
                  placeholder="First name"
                  variant="filled"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                  styles={() => ({
                    input: {
                      backgroundColor: "white",
                    },
                  })}
                />
                <TextInput
                  label="Last name"
                  placeholder="Last name"
                  variant="filled"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                  styles={() => ({
                    input: {
                      backgroundColor: "white",
                    },
                  })}
                />
              </SimpleGrid>
              <Space h="xs" />
              <TextInput
                label="Email address"
                placeholder="Email address"
                variant="filled"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                styles={() => ({
                  input: {
                    backgroundColor: "white",
                  },
                })}
              />
              <Space h="md" />
              <Button
                w={"fit-content"}
                size="sm"
                bg={Colors.brandTwo}
                loading={loading}
                type="submit"
              >
                {isSuccess ? (
                  <IconCircleCheck
                    color="white"
                    stroke={2}
                    size={20}
                    variant="filled"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

function SponsorSiteModal(props) {
  const theme = useMantineTheme();
  const { opened, onClose, siteData } = props;
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = ({ data, resetForm }) => {
    addProspectiveSponsor({
      ...data,
      startLoader: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);

        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
          resetForm();
        }, 1000);
      },
      stopLoader: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      radius={"lg"}
      styles={() => ({
        close: {
          backgroundColor: Colors.brandTwo,
          color: "white",
          borderRadius: 9,
        },
      })}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <Text c={Colors.brandTwo} fw={600} size="xl">
        How to sponsor{" "}
        <Text
          component="span"
          c={Colors.brandTwo}
          fw={600}
          size="xl"
          tt="capitalize"
        >
          {siteData?.name}
        </Text>
      </Text>
      <Space h={"md"} />
      <Box>
        <Text fw={600} size="md">
          Paybill details (KES)
        </Text>
        <Space h="xs" />
        <Stack gap={0}>
          <Text size="sm" c={theme.colors.gray[7]}>
            Business no:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              7234187
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            Acc Name:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              Project Name eg{" "}
              <Text
                component="span"
                size="md"
                c={theme.colors.gray[9]}
                tt="capitalize"
              >
                {siteData?.name}
              </Text>
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            NB: Paybill will read{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UNTAPPED WATER
            </Text>
            .
          </Text>
        </Stack>
      </Box>
      <Space h={"xl"} />
      <Box>
        <Text fw={600} size="md">
          Bank Details (USD)
        </Text>
        <Space h="xs" />
        <Stack gap={0}>
          <Text size="sm" c={theme.colors.gray[7]}>
            ACCOUNT NAME:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UNTAPPED WATER LIMITED
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            ACCOUNT NUMBER:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              4616780023 (USD)
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BANK NAME:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              NCBA BANK
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BANK CODE:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              07
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            SWIFT CODE:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              CBAFKENX
            </Text>
          </Text>
          <Text size="sm" c={theme.colors.gray[7]}>
            BRANCH:{" "}
            <Text component="span" size="md" c={theme.colors.gray[9]}>
              UPPER HILL
            </Text>
          </Text>
        </Stack>
      </Box>
      <Divider my={"lg"} color="black" />
      <Box>
        <Text fw={600} c={Colors.brandTwo} size="xl">
          Let’s stay in touch
        </Text>
        <Text c={theme.colors.gray[7]} size="sm">
          Transparency is at the core of Untapped Water, as it fosters trust and
          accountability among our stakeholders. We believe that by openly
          sharing our processes, decisions, and data, we can create a more
          informed community that is engaged in the vital mission of sustainable
          water management.
        </Text>
        <Space h="sm" />
        <Formik
          enableReinitialize
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit({ data: values, resetForm });
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Required"),
          })}
        >
          {({ handleChange, values, errors }) => (
            <Form>
              <SimpleGrid cols={2}>
                <TextInput
                  label="First name"
                  placeholder="First name"
                  variant="filled"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                />
                <TextInput
                  label="Last name"
                  placeholder="Last name"
                  variant="filled"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                />
              </SimpleGrid>
              <Space h="xs" />
              <TextInput
                label="Email address"
                placeholder="Email address"
                variant="filled"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
              <Space h="md" />
              <Button
                w={"fit-content"}
                size="sm"
                bg={Colors.brandTwo}
                loading={loading}
                type="submit"
              >
                {isSuccess ? (
                  <IconCircleCheck
                    color="white"
                    stroke={2}
                    size={20}
                    variant="filled"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

function SponsorSite(props) {
  const sites = props.sites;

  const [showModal, setShowModal] = useState(false);
  const [siteData, setSiteData] = useState();

  function calculateProgress(amountCollected, targetAmount) {
    if (targetAmount <= 0) {
      return 0;
    }

    // Calculate percentage, round it off, and clamp between 1 and 100
    const progress = Math.round((amountCollected / targetAmount) * 100);
    return Math.min(Math.max(progress, 1), 100);
  }

  return (
    <Box w={"100%"}>
      <Stack>
        {sites?.map((site, index) => (
          <>
            <Group
              wrap="nowrap"
              gap={"xl"}
              dir={index % 2 === 0 ? "ltr" : "rtl"}
              key={site?.id}
              w={"100%"}
              visibleFrom="md"
            >
              <img
                src={site?.image}
                style={{
                  height: rem(350),
                  width: rem(450),
                  objectFit: "cover",
                  borderRadius: "24px",
                }}
                alt={site.name}
              />
              <Stack gap={"xs"} dir="ltr" flex={1}>
                <Box>
                  <Text fw={600} tt="capitalize">
                    {site.name}
                  </Text>
                  <Text>{site.description}</Text>
                </Box>
                <Paper bg={"#fff"} radius={8} p={"sm"}>
                  <Stack gap={5}>
                    <Progress
                      value={calculateProgress(
                        site?.amount_collected,
                        site?.target_amount
                      )}
                      size={"xl"}
                      radius={"xl"}
                      styles={() => ({
                        root: {
                          backgroundColor: Colors.brandTwo,
                          padding: 4,
                        },
                        section: {
                          backgroundColor: Colors.brandOne,
                          borderRadius: "10px",
                        },
                      })}
                    />
                    <Text size="xs" fw={600}>
                      Collected {formatToUSDCurrency(site?.amount_collected)} out
                      of {formatToUSDCurrency(site?.target_amount)}
                    </Text>
                  </Stack>
                </Paper>
                <Button
                  bg={Colors.brandTwo}
                  size="sm"
                  w="fit-content"
                  mt={"md"}
                  onClick={() => {
                    setSiteData(site);
                    setShowModal(true);
                  }}
                >
                  Get Involved
                </Button>
              </Stack>
            </Group>

            <Stack
              gap={"lg"}
              key={`${index}-mobile`}
              w={"100%"}
              hiddenFrom="md"
            >
              <img
                src={site?.image}
                style={{
                  height: rem(350),
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "24px",
                }}
                alt="kiambu"
              />
              <Stack gap={"xs"}>
                <Box>
                  <Text fw={600} tt="capitalize">
                    {site.name}
                  </Text>
                  <Text>{site.description}</Text>
                </Box>
                <Paper bg={"#fff"} radius={8} p={"sm"}>
                  <Stack gap={5}>
                    <Progress
                      value={calculateProgress(
                        site?.amount_collected,
                        site?.target_amount
                      )}
                      size={"xl"}
                      radius={"xl"}
                      styles={() => ({
                        root: {
                          backgroundColor: Colors.brandTwo,
                          padding: 4,
                        },
                        section: {
                          backgroundColor: Colors.brandOne,
                          borderRadius: "10px",
                        },
                      })}
                    />
                    <Text size="xs" fw={600}>
                      Collected {formatToUSDCurrency(site?.amount_collected)} out
                      of {formatToUSDCurrency(site?.target_amount)}
                    </Text>
                  </Stack>
                </Paper>
                <Button
                  bg={Colors.brandTwo}
                  size="sm"
                  w="fit-content"
                  mt={"md"}
                  onClick={() => {
                    setSiteData(site);
                    setShowModal(true);
                  }}
                >
                  Get Involved
                </Button>
              </Stack>
            </Stack>
          </>
        ))}
      </Stack>

      {showModal ? (
        <SponsorSiteModal
          opened={showModal}
          onClose={() => setShowModal(false)}
          siteData={siteData}
        />
      ) : null}
    </Box>
  );
}

function SponsorUs() {
  const theme = useMantineTheme();

  const items = [
    {
      name: "Site visits (3)",
      amount: 585,
    },
    {
      name: "O&M Support",
      amount: 400,
    },
    {
      name: "Team's Compensation",
      amount: 800,
    },
  ];

  return (
    <Box>
      <Image
        src="/images/sponsor-us.png"
        style={{
          width: "100%",
          height: rem(300),
          objectFit: "cover",
          borderRadius: 24,
        }}
      />
      <Space h="lg" />
      <Text size="md" c={theme.colors.gray[7]}>
        Sponsoring us allows us to to set up more sites. Sample use of funds:
      </Text>
      <Space h="sm" />
      <List type="ordered" c={theme.colors.gray[7]} size="md">
        <List.Item>
          Loans (after repayment they are used to set up new sites)
        </List.Item>
        <List.Item>Operating expenses</List.Item>
        <List.Item>$800 monthly compensation</List.Item>
      </List>
      <Space h="xl" />
      <ScrollAreaAutosize>
        <Stack gap={0}>
          <Box px={10} py={5} flex={1} bg={Colors.brandTwo}>
            <Text tt="uppercase" fw={600} c={"white"}>
              Monthly budget
            </Text>
          </Box>
          <Table withRowBorders={false}>
            <Table.Thead>
              <Table.Tr bg={Colors.brandOne}>
                <Table.Th>Item</Table.Th>
                <Table.Th>Amount</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {items?.map((item, index) => (
                <Table.Tr bg={"white"} key={index}>
                  <Table.Td>
                    <Text c={Colors.brandTwo}>{item.name}</Text>
                  </Table.Td>
                  <Table.Td>
                    <Text c={Colors.brandTwo}>
                      {formatToUSDCurrency(item.amount)}
                    </Text>
                  </Table.Td>
                </Table.Tr>
              ))}

              <Table.Tr bg={Colors.brandOne}>
                <Table.Td>
                  <Text fw={600}>Total</Text>
                </Table.Td>
                <Table.Td>
                  <Text fw={600}>{formatToUSDCurrency(1785)}</Text>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Stack>
      </ScrollAreaAutosize>
      <Space h="xl" />
      <SponsorUsDetails />
    </Box>
  );
}

export default function GetInvovled(props) {
  const theme = useMantineTheme();
  const [sites, setSites] = useState([]);

  useEffect(() => {
    fetchSites({
      onSuccess: (data) => {
        if (data?.data?.records?.length) {
          let arr = [];

          data?.data?.records?.forEach((item) => {
            if (item?.fields?.name) {
              arr.push({
                ...item.fields,
                image: item?.fields?.image?.[0]?.url,
                id: item?.id,
              });
            }
          });
          setSites(arr);
        }
      },
    });
  }, []);

  return (
    <>
      {sites?.length ? (
        <section id="get-involved">
          <Box
            px={rem(150)}
            py={rem(50)}
            visibleFrom="md"
            bg={theme.colors.gray[1]}
          >
            <Text size="xl" ta={"center"} fw={600} tt="uppercase">
              Get Involved
            </Text>
            <Space h="30px" />
            <Tabs
              variant="pills"
              radius={"xl"}
              defaultValue={"sponsor-site"}
              styles={() => ({
                list: {
                  marginBottom: "40px",
                },
              })}
              classNames={{ tab: "get-involved-tab" }}
            >
              <Tabs.List justify="center">
                <Tabs.Tab value="sponsor-site">Sponsor a site</Tabs.Tab>
                <Tabs.Tab value="sponsor-us">Sponsor us</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="sponsor-site">
                <SponsorSite sites={sites} />
              </Tabs.Panel>

              <Tabs.Panel value="sponsor-us">
                <SponsorUs />
              </Tabs.Panel>
            </Tabs>
          </Box>

          <Box px={"md"} py="50px" hiddenFrom="md" bg={theme.colors.gray[1]}>
            <Text size="xl" ta={"center"} fw={600} tt="uppercase">
              Get Involved
            </Text>
            <Space h="30px" />
            <Tabs
              variant="pills"
              radius={"xl"}
              defaultValue={"sponsor-site"}
              styles={() => ({
                list: {
                  marginBottom: "40px",
                },
              })}
              classNames={{ tab: "get-involved-tab" }}
            >
              <Tabs.List justify="center">
                <Tabs.Tab value="sponsor-site">Sponsor a site</Tabs.Tab>
                <Tabs.Tab value="sponsor-us">Sponsor us</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="sponsor-site">
                <SponsorSite sites={sites} />
              </Tabs.Panel>

              <Tabs.Panel value="sponsor-us">
                <SponsorUs />
              </Tabs.Panel>
            </Tabs>
          </Box>
        </section>
      ) : null}
    </>
  );
}
